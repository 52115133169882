import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "answer",
    "showTranslationButton",
    "showOriginalButton",
    "flagUserLang",
    "flagReviewLang",
    "loader",
    "copy",
    "copyResponseAlert"
  ]
  static values = {
    translated: Boolean,
    original: String,
    translation: String,
    review: String,
    id: Number,
    index: String,
    signature: String
  }

  copy(){
    this.copyTarget.classList.toggle("d-none")
    const answer = this.answerTarget.innerText + "\n\n" + this.signatureValue
    navigator.clipboard.writeText(answer)
    setTimeout(() => {
      this.copyTarget.classList.toggle("d-none")
    }, 1500);
  }

  generateAnswer(){
    this.loaderTarget.style.display="flex"
    const url = `/answers/updateAnswer?review_id=${this.idValue}`
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    fetch(url, {
        method: "POST",
        headers: {
          "X-CSRF-Token": token,
          "Accept": "text/plain",
        },
        body: JSON.stringify({ review_id: this.idValue })
      })
      .then(response => response.text())
      .then((data) => {
        this.answerTarget.innerHTML = data;
        this.loaderTarget.style.display = "none";
      })
      .catch((error) => {
        console.error("Erreur:", error);
        this.loaderTarget.style.display = "none";
      });
  }


  translate(event){
    event.preventDefault()
    if (this.translatedValue) {
      if (this.answerTarget.innerHTML === this.originalValue) {
        this.answerTarget.innerHTML = this.translationValue
        this.#toggleButton()
      } else {
        this.answerTarget.innerHTML = this.originalValue
        this.#toggleButton()
      }
    } else {
      this.loaderTarget.style.display="flex"
      const url = `/answers/${this.idValue}/translate_answer?answer=${this.indexValue}&answerValue=${this.answerTarget.innerText}`
      fetch(url, { headers: {"Accept": "application/json" }})
        .then(response => response.json())
        .then((data) => {
          switch (this.indexValue) {
            case '1':
              this.answerTarget.innerHTML = data.answer.answer_1
              this.translationValue = data.answer.answer_1
              break;
            case '2':
              this.answerTarget.innerHTML = data.answer.answer_2
              this.translationValue = data.answer.answer_2
              break;
            case '3':
              this.answerTarget.innerHTML = data.answer.answer_3
              this.translationValue = data.answer.answer_3
              break;
            default:
              break;
          }
          this.translatedValue = true
          this.#toggleButton()
          this.loaderTarget.style.display="none"
        })
    }
  }

  #toggleButton() {
    this.flagReviewLangTarget.classList.toggle("d-none")
    this.flagUserLangTarget.classList.toggle("d-none")
    this.showTranslationButtonTarget.classList.toggle("d-none")
    this.showOriginalButtonTarget.classList.toggle("d-none")
  }
}
